import React from "react";
import ReactDOM from "react-dom";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

import LoginScreen from "~/screens/home/login-screen.jsx";

import "~/styles/general.scss";

UIkit.use(Icons);
class App extends React.PureComponent {
	pageType() {
		let page = window.location.pathname;
		if (page == "/") return <LoginScreen />;
	}
	render() {
		return this.pageType();
	}
}

ReactDOM.render(<App />, document.getElementById("home"));
