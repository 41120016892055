import React from "react";

import { Login, ForgotPassword } from "~/actions/action-home";

export default class LoginScreen extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = { email: "", password: "", errors: {}, loader: false, disableForgotBtn: false };
		this.handleChange = this.handleChange.bind(this);
		this.handleFocus = this.handleFocus.bind(this);
		this.userLogin = this.userLogin.bind(this);
		this.handleEnter = this.handleEnter.bind(this);
		this.pressEnterCheck=this.pressEnterCheck.bind(this);
	}

	handleChange({ target }) {
		this.setState({ [target.name]: target.value });
	}

	handleFocus({ target }) {
		if (!!this.state.errors[target.name]) {
			let errors = { ...this.state.errors };
			delete errors[target.name];
			this.setState({ errors: errors });
		}
	}

	handleEnter(e) {
		if(e.keyCode === 13) {
			this.userLogin();
		}
	}

	userLogin() {
		const errors = {};
		const email_validator = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,3})$/;

		if (this.state.email.length == 0) {
			errors.email = "Email should not be empty";
		} else if (!email_validator.test(this.state.email)) {
			errors.email = "Invalid email!";
		}

		if (this.state.password == "") {
			errors.password = "Please enter the password";
		}

		if (Object.keys(errors).length > 0) {
				this.setState({ errors: errors, loader: false});
		} else {
			this.setState({ loader: true, errors });
			Login(this.state.email, this.state.password).then(() => {
				window.location = window.location.origin + "/home";
			}).catch(() => this.setState({ loader: false }));
		}
	}
	pressEnterCheck(e) {
		if (e.charCode == 13) {
			this.userLogin();
		}
	}
	render() {
		return (
			<div>
				<div className="loginWrap">
				<div className="adminLoginLogo"><img src="/images/admin-login-logo.png" /><h3>HEALTH<span className="mug">MUG</span> <span>Admin</span></h3></div>
				<div className="form-horizontal">
					<div className="form-group">                
						<div className="col-xs-12">
							<input value={this.state.email} onChange={this.handleChange} type="text" name="email" className="form-control" placeholder="User Id"/>
							<div className="inputError light">{this.state.errors.email}</div>
						</div>
					</div>
					<div className="form-group">                
						<div className="col-xs-12">
							<input onKeyPress={this.pressEnterCheck} value={this.state.password} onChange={this.handleChange} type="password" name="password" className="form-control" placeholder="Password"/>
							<div className="inputError light">{this.state.errors.password}</div>
						</div>
					</div>
					<div className="form-group">
						<div className="col-xs-12">
							<button onClick={this.userLogin}  disabled={this.state.loader} type="submit" className="btn btn-block btn-lg btn-login">
							{this.state.loader ? <span uk-spinner="ratio:0.5" /> : "Sign In"}</button>
						</div>
					</div>
				</div>
			</div>
			</div>
		);
	}
}
