import { DOCTOR_ADMIN_URL, SELLER_ADMIN_URL, COURIER_ADMIN_URL, DATA_ADMIN_URL, MARKETING_ADMIN_URL } from "~/actions/types";

//Function for login admin panel
export const Login = (email, password) => {
	return fetch(window.location.origin + "/login/userlogin", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ email: email, pwd: password }),
	})
		.then((response) => {
			if (!response.ok) {
				return Promise.reject(response);
			}
			return response.text();
		})
		.then((data) => {
			console.log(data);
			if (data == "True") {
				const promise1 = LoginDoctorAdmin(email, password);
				const promise2 = LoginSellerAdmin(email, password);
				const promise3 = LoginCourierAdmin(email, password);
				const promise4 = LoginDataAdmin(email, password);
				const promise5 = LoginMarketingAdmin(email, password);
				return Promise.any([promise1, promise2, promise3, promise4]);
			} else {
				alertify.error("User Id or Password is incorrect");
				return Promise.reject();
			}
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};

//Function for login on doctor admin panel
export const LoginDoctorAdmin = (email, password) => {
	return fetch(DOCTOR_ADMIN_URL + "/home/userlogin", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ email: email, password: password }),
	})
		.then((response) => {
			if (!response.ok) {
				return Promise.reject(response);
			}
			return response.text();
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};

//Function for login on seller admin panel
export const LoginSellerAdmin = (email, password) => {
	return fetch(SELLER_ADMIN_URL + "/login/userlogin", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ email: email, password: password }),
	})
		.then((response) => {
			if (!response.ok) {
				return Promise.reject(response);
			}
			return response.text();
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};

//Function for login on courier admin panel
export const LoginCourierAdmin = (email, password) => {
	return fetch(COURIER_ADMIN_URL + "/home/user-login", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ email: email, password: password }),
	})
		.then((response) => {
			if (!response.ok) {
				return Promise.reject(response);
			}
			return response.text();
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};

//Function for login on data admin panel
export const LoginDataAdmin = (email, password) => {
	return fetch(DATA_ADMIN_URL + "/login/is-valid-user", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ email: email, password: password }),
	})
		.then((response) => {
			if (!response.ok) {
				return Promise.reject(response);
			}
			return response.text();
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};

//Function for login on marketing panel
export const LoginMarketingAdmin = (email, password) => {
	return fetch(MARKETING_ADMIN_URL + "/login/userlogin", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ email: email, pwd: password }),
	})
		.then((response) => {
			if (!response.ok) {
				return Promise.reject(response);
			}
			return response.text();
		})
		.catch((error) => {
			if (error instanceof Error) alertify.error(error);
			else error.text().then((data) => alertify.error(data));
			return Promise.reject();
		});
};
